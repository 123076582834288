.notification_dropdown {
  width: 497px;
  min-height: 200px;
  max-height: 547px;

  .topbar {
    @include flex-position(space-between, center);
    padding: 2rem 1.5rem;
    border-bottom: 1px solid $color-grey-200;

    .left {
      @include flex-position(center, center);
      background: $color-grey-200;
      gap: 1rem;
      padding: 0.5rem 1rem;

      p {
        font-size: 12px;
        font-weight: 600;
        color: $color-grey-500;
      }
    }

    .right {
      @include flex-position(center, center);
    }
  }

  .content {
    padding: 0 2rem 2rem;
    .item {
      @include flex-position(center, center);
      gap: 1rem;
      padding: 2rem 0;
      border-bottom: 1px solid $color-grey-200;

      .item_content {
        @include flex-position(flex-start, left);
        flex-direction: column;
        gap: 8px;
        .item_title {
          font-size: 16px;
          font-weight: 500;
        }
        .item_detail {
          font-size: 12px;
          font-weight: 400;

          span {
            color: $color-amber;
            text-decoration: underline;
          }
        }
      }
    }
    .button_div {
      @include flex-position(center, center);
      padding-top: 1.2rem;

      button {
        background: $color-white;
        color: $color-primary-600;
        border: 2px solid $color-primary-600;
      }
    }
  }
}

.notification_tab_container {
  padding: 0 32px;
  background: $color-white;
  width: 100%;
  cursor: pointer;

  .inner {
    @include flex-position(space-between, center);
    padding: 1rem;
    border-top: 1px solid $color-grey-100;
    border-bottom: 1px solid $color-grey-100;
    overflow-x: auto !important;

    .active {
      background: $color-grey-100;
      border-radius: 8px;
    }
    .tab_item {
      @include flex-position(center, center);
      gap: 10px;
      padding: 8px 12px;
      min-width: 185px;
      cursor: pointer;
      &:hover {
        background: $color-grey-100;
        border-radius: 8px;
      }

      .name {
        font-size: 14px;
        font-weight: 600;
        color: $color-grey-500;
      }
      .new {
        font-size: 12px;
        font-weight: 400;
      }
      .primary {
        color: $color-primary-600;
      }
      .amber {
        color: $color-amber;
      }
      .sky {
        color: $color-sky;
      }
      .pink {
        color: $color-pink-500;
      }
    }
  }
}

.notification_list {
  padding: 0 32px;
  background: $color-white;
  width: 100%;
  .inner {
    padding-top: 1rem;
    .item_container {
      @include flex-position(space-between, center);
      // margin: 0 2rem;
      border-bottom: 1px solid $color-grey-200;
      gap: 5rem;
      padding: 0 1rem;
      cursor: pointer;

      &:hover {
        background: $color-grey-100;
      }

      &.active {
        background: $color-grey-100;
      }

      button {
        font-size: 14px;
        min-width: 140px;
      }
      @include mdScreen {
        @include flex-position(space-between, flex-start);
        gap: 0rem;
        flex-direction: column;
      }
      .item {
        @include flex-position(flex-start, flex-start);
        gap: 1rem;
        padding: 2rem 0;
        width: 100%;
        .checkbox {
          .check-input {
            position: relative;
            margin-top: 3px;
            margin-right: 0;
            width: 26px;
            height: 26px;
            border: 1px solid $color-grey-400;
            cursor: pointer;

            vertical-align: top;
            appearance: none;
            background: $color-white;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            border-radius: 6.8px;

            &:focus {
              border: 1px solid $color-grey-400 !important;
            }

            &:checked {
              background-image: url("../../assets/svg/check.svg");
              background-color: $color-primary-600;
              border: $color-primary-600;
            }
          }
        }
        .icon {
          @include flex-position(flex-start, center);
          flex-direction: column;
        }

        .item_content {
          @include flex-position(flex-start, left);
          flex-direction: column;
          gap: 13px;
          width: 100%;
          .item_title {
            font-size: 18px;
            font-weight: 500;
          }
          .read {
            color: $color-grey-400;
          }
          .item_detail {
            @include flex-position(space-between, center);
            width: 100%;
            p {
              font-size: 14px;
              font-weight: 400;
              line-height: 22px;

              span {
                color: $color-amber;
                text-decoration: underline;
              }
            }
            .right {
              @include flex-position(center, center);
              min-width: 10rem;
            }
          }
        }

        .action {
          min-width: 150px;
        }
      }
    }
  }
}

.notification_table_container {
  padding: 0 32px;
  background: $color-white;
  width: 100%;
  .table_row {
    cursor: pointer;

    .read {
      p {
        color: $color-grey-400 !important;
      }
    }
    &:hover {
      background: $color-grey-100 !important;
    }
  }

  .title {
    @include flex-position(flex-start, center);
    gap: 10px;
    .check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0;
      width: 34px;
      height: 34px;
      border: 1px solid $color-grey-400;
      cursor: pointer;

      vertical-align: top;
      appearance: none;
      background: $color-white;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      border-radius: 6.8px;

      &:focus {
        border: 1px solid $color-grey-400 !important;
      }

      &:checked {
        background-image: url("../../assets/svg/check.svg");
        background-color: $color-primary-600;
        border: $color-primary-600;
      }
    }
    p {
      font-size: 18px;
      font-weight: 600;
      // line-height: 27px;
    }
  }

  .content {
    font-size: 14px;
    font-weight: 400;

    span {
      color: $color-amber;
      text-decoration: underline;
    }
  }

  .date {
    font-size: 14px;
    font-weight: 400;
  }
}

.notification_date_filter {
  @include flex-position(flex-start, flex-start);
  flex-direction: column;
  gap: 2.5rem;
  min-width: 417px;
  padding: 2rem;
  .heading {
    @include flex-position(flex-start, center);
    gap: 0.8rem;

    .filter_icon {
      position: static !important;
    }

    p {
      font-size: 14px;
      font-weight: 500;
    }
  }
  .date {
    @include flex-position(center, center);
    flex-direction: column;
    width: 100%;
    gap: 1rem;
    p {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }
  }
}
