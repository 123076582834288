.modal {
  position: fixed;
  z-index: 1;
  padding: 50px;
  // left: 0;
  // top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);

  @include mdScreen {
    padding-left: 24px;
    padding-right: 24px;
  }

  @include smScreen {
    padding-left: 12px;
    padding-right: 12px;
  }

  .modal-dialog {
    .enter_prompt_form {
      @include flex-position(flex-start, center);
      padding: 6px 6px 6px 12px;
      width: 100%;
      border: 1px solid $color-grey-200;
      border-radius: 12px;

      .generate {
        width: 120px;
        font-size: 14px;
        color: $color-white;
        background: $color-primary-600;
        border: none;
      }

      &:focus-within {
        border: 1px solid $color-primary-600;
      }

      .form_group_container {
        margin: 0;
        width: inherit;

        .flex_input {
          padding: 0;
          font-size: 0.95em;
          border: none;

          &:focus-within {
            border: none !important;
          }
        }
      }

      .btn {
        padding: 8px 16px;
        padding-bottom: 9px;
        font-size: 0.92em;
        font-weight: 500;
        border-radius: 8px;

        &.loading {
          padding: 7px 16px !important;
          padding-bottom: 8px !important;

          .loader_img {
            width: 22px;
          }
        }
      }
    }
    &.size_sm {
      .modal-content {
        width: 378px;
        max-width: 378px;

        @media screen and (max-width: 400px) {
          width: 100%;
        }
      }
    }

    &.size_md {
      .modal-content {
        width: 500px;
        max-width: 500px;

        @media screen and (max-width: 500px) {
          width: 100%;
        }
      }
    }

    .modal-content {
      margin: auto;
      border: 1px solid $color-grey-100;
      width: 100%;
      max-width: 700px;
      background: $color-white;
      border-radius: 12px;

      @include mdScreen {
        width: 100%;
      }

      .modal_header {
        padding: 18px 24px;
        border-bottom: 1px solid $color-grey-100;

        @include mdScreen {
          padding: 12px;
        }

        .title_box {
          padding: 10px 12px;
          font-size: 0.8em;
          font-weight: 600;
          width: fit-content;
          color: $color-grey-500;
          background: $color-grey-50;
          border-radius: 8px;
        }
      }

      .modal_body {
        padding: 28px 24px;

        @include mdScreen {
          padding: 28px 12px;
        }
      }

      .modal_action {
        @include flex-position(flex-end, center);
        margin-top: 40px;

        @include smScreen {
          margin-top: 30px;
        }
      }
    }

    &.store_data_synching_modal {
      .modal_body {
        text-align: center;
        padding: 2rem 4rem 4rem;

        .header {
          font-size: 24px;
          font-weight: 600;
          margin: 1rem 0;
        }

        .sub_header {
          font-size: 18px;
          font-weight: 500;
        }

        .loader {
          margin: 3rem 0;

          p {
            margin-top: 1rem;
            font-size: 18px;
          }
        }

        .know_texts {
          margin: 4rem 0 2rem;
        }
      }
    }

    &.confirmation_modal {
      .modal_header .head_text {
        font-weight: 600;
        letter-spacing: 0.3px;
      }

      .modal_body {
        .confirmation_text {
          font-size: 0.97em;
          line-height: 150%;
        }

        .btn_box {
          margin-top: 2rem;
          @include flex-position(center, center);
          gap: 2rem;
          .danger {
            background: $color-error;
            color: $color-white;
            border: 1px solid $color-error;
          }
          .btn {
            margin: 0 !important;
          }
        }

        .btn {
          margin: 32px 0 0 auto;
        }
      }
    }

    &.campaign_sender_confirmation_modal {
      .modal_body {
        .content_box {
          @include flex-position(center, center);
          flex-direction: column;
          gap: 3rem;
          padding: 2rem;
          text-align: center;

          p {
            font-size: 14px;
            line-height: 22.4px;

            strong {
              &.konvas {
                color: $color-primary-600;
              }

              &.klaviyo {
                color: $color-grey-900;
              }
            }
          }

          .btn_box {
            @include flex-position(center, center);
            gap: 2rem;
          }
        }
      }
    }

    &.campaign_recipient_limit_modal {
      .modal_body {
        .content_box {
          @include flex-position(center, center);
          flex-direction: column;
          gap: 3rem;
          padding: 2rem;
          text-align: center;

          p {
            font-size: 20px;
            line-height: 150%;

            strong {
              &.konvas {
                color: $color-primary-600;
              }

              &.klaviyo {
                color: $color-grey-900;
              }
            }
          }

          .btn_box {
            @include flex-position(center, center);
            gap: 2rem;
          }
        }
      }
    }

    &.new_campaign_modal {
      margin: 0;

      .modal_header {
        .title {
          padding: 8px 12px;
          font-size: 0.8em;
          font-weight: 600;
          color: $color-grey-500;
          text-transform: uppercase;
          background: $color-grey-50;
          border-radius: 8px;
        }
      }

      .modal_body {
        min-height: 75vh;

        &.step_one {
          @include flex-position(center, center);
          flex-direction: column;
          gap: 32px;
          margin: 0 auto;
          padding-top: 48px;
          padding-bottom: 56px;
          width: 400px;

          .option {
            @include flex-position(center, center);
            padding: 16px 12px;
            width: 100%;
            font-size: 0.95em;
            font-weight: 600;
            color: $color-grey-900;
            border: 1px solid $color-primary-600;
            border-radius: 12px;
            cursor: pointer;

            &.disabled {
              color: $color-white;
              background: $color-grey-500;
              border: 1px solid $color-grey-500;
              cursor: not-allowed;

              span {
                color: $color-white;

                strong {
                  font-size: 12px;
                  font-weight: 600 !important;
                }
              }
            }

            &.active {
              color: $color-white;
              background: $color-primary-600;
            }
          }

          .or {
            font-size: 0.95em;
            font-weight: 600;
            color: $color-grey-900;
          }
        }

        &.step_two {
          @include flex-position(center, center);
          flex-direction: column;
          gap: 32px;
          margin: 0 auto;
          padding-top: 48px;
          padding-bottom: 56px;
          width: 400px;

          .form_group_container {
            margin: 138px 0px;
            width: 100%;
          }

          .btn {
            width: 100%;
          }
        }

        &.no_customers {
          padding: 2rem;

          p {
            font-size: 16px;
          }
        }

        &.step_three {
          .campaign_title_label {
            margin-bottom: 8px;
            font-size: 0.85em;
            font-weight: 600;
            color: $color-grey-900;
          }

          .campaign_title {
            margin-bottom: 32px;
            font-size: 0.95em;
            font-weight: 600;
            color: $color-primary-600;
            text-transform: uppercase;
          }

          .info {
            @include flex-position(space-between, flex-start);
            margin-bottom: 32px;

            .item {
              max-width: 75%;

              .label {
                margin-bottom: 8px;
                font-size: 0.85em;
                font-weight: 600;
                color: $color-grey-900;
              }

              .value {
                @include flex-position(flex-start, center);
                font-size: 0.85em;
                font-weight: 500;
                color: $color-primary-600;

                .dot {
                  margin-right: 5px;
                  width: 6px;
                  height: 6px;
                  background: $color-primary-600;
                  border-radius: 50%;
                }
              }
            }
          }

          .marketing {
            .title {
              margin-bottom: 3px;
              font-size: 0.95em;
              font-weight: 500;
              color: $color-grey-900;
            }

            .feedback {
              margin-bottom: 18px;
              font-size: 0.88em;
              color: $color-grey-500;
              white-space: pre-line;
              word-wrap: break-word;
            }

            .recommendation {
              font-size: 0.88em;
              font-weight: 500;
              color: $color-grey-500;
              font-style: italic;
            }
          }

          .message_container {
            margin-top: 32px;
            padding: 24px 16px;
            background: $color-grey-50;
            border-radius: 14px;

            @include mdScreen {
              padding: 24px 12px;
            }

            .loader_img_container {
              margin: 80px 0;
            }

            .section_header_container {
              .title {
                font-size: 1.3em;
                font-weight: 700;
              }

              .btn_text {
                font-weight: 600;
              }
            }

            .message_text {
              font-size: 0.875em;
              font-weight: 400;
              line-height: 150%;
              color: $color-grey-500;

              p,
              a,
              span,
              ul,
              ol,
              li {
                margin-block-start: 1em;
                margin-block-end: 1em;
                margin-inline-start: 0px;
                margin-inline-end: 0px;
                unicode-bidi: isolate;
                font-family: inherit !important;
              }

              a {
                font-weight: 600;
                color: $color-primary-600;
              }

              ol,
              ul {
                display: block;
                list-style-type: decimal;
                padding-inline-start: 18px;
                unicode-bidi: isolate;

                li {
                  margin: 0;
                  margin-left: 15px;
                  list-style: auto;
                }
              }
            }
          }

          .modal_action {
            @include flex-position(space-around, center);
            margin-top: 56px;
            margin-bottom: 32px;

            .btn {
              min-width: 150px;

              &.btn_draft {
                color: $color-white;
                background: $color-success;
                border: 1px solid $color-success;
              }

              &.btn_error {
                color: $color-white;
                background: $color-error;
                border: 1px solid $color-error;
              }
            }
          }
        }

        &.step_four {
          .generating_data {
            @include flex-position(center, center);
            flex-direction: column;
            margin: 150px auto;
            max-width: 400px;
            width: 100%;

            .title {
              font-size: 1.3em;
              font-weight: 500;
              text-align: center;
              color: $color-grey-500;

              @include smScreen {
                font-size: 1.6em;
              }
            }

            .loader_img_container {
              margin: 32px 0px 0px 0px;
            }

            .please_wait {
              margin-top: 24px;
              font-size: 0.95em;
              font-weight: 500;
              color: $color-grey-500;
            }
          }
        }

        .enter_prompt_form_with_margin {
          margin-top: 56px;
          margin-bottom: 32px;
        }
      }
    }

    &.campaign_message_preview_modal {
      .modal_body {
        position: relative;

        .note {
          font-size: 12px;
          font-style: italic;
          font-weight: 400;
          color: $color-grey-500;
          margin-bottom: 2rem;
        }

        .campaign_body {
          height: 50rem;
          overflow: auto;
          margin-bottom: 5rem;

          .heading {
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 6px;
          }

          .subheading {
            font-size: 14px;
            font-weight: 500;
          }

          .personalized_message {
            margin-top: 2rem;

            p {
              color: $color-grey-500;
              font-size: 14px;
              margin-bottom: 1.5rem;
            }
          }
        }

        .slider {
          @include flex-position(center, center);
          width: 90%;
          gap: 1rem;
          bottom: 0;
          padding: 2rem;
          position: absolute;
          background: $color-white;

          p {
            font-size: 14px;
            font-weight: 600;
          }

          .icon {
            @include flex-position(center, center);

            svg {
              cursor: pointer;
            }
          }
        }
      }
    }

    &.new_ai_segment_modal {
      .modal_body {
        .info {
          margin-bottom: 24px;

          &:last-child {
            margin: 0;
          }

          .flex_input {
            padding: 10px 14px;
          }

          label {
            display: block;
            margin-bottom: 6px;
            font-size: 0.85em;
            font-weight: 500;
            letter-spacing: 0.4px;
          }

          p {
            font-size: 0.97em;
            font-weight: 400;
            width: fit-content;
          }

          .list {
            @include flex-position(flex-start, flex-start);
            flex-wrap: wrap;
            column-gap: 6px;
            line-height: 150%;
          }
        }

        .create_custom_insight_actions {
          @include flex-position(flex-end, center);
          gap: 1rem;

          .generate_insight_campaign {
            background: $color-white;
            color: $color-primary-600;
            border: 1px solid $color-primary-600;
          }

          button {
            margin: 1rem 0;
          }
        }

        .btn {
          margin: 42px 0 0 auto;
        }
      }
    }

    &.notification_modal {
      .modal_body {
        .notification_details {
          font-size: 14px;
          font-weight: 400;
          line-height: 150%;
          color: $color-grey-500;

          h3 {
            font-weight: 600 !important;
            font-size: 16px !important;
          }

          p {
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            unicode-bidi: isolate;
            font-family: inherit !important;
          }
        }

        .btn {
          margin: 42px 0 0 auto;
        }
      }
    }

    &.campaign_personalization_modal {
      .modal_body {
        .icon {
          float: right;
          cursor: pointer;
        }

        .personalization {
          padding: 4rem;

          .title {
            font-size: 24px;
            font-weight: 500;
            line-height: 32.05px;
            text-align: center;
            margin-bottom: 2rem;
          }

          .text {
            font-size: 14px;
            font-weight: 400;
            line-height: 22.4px;
            color: $color-grey-500;
          }
        }

        .btn_div {
          margin-top: 2rem;
          @include flex-position(center, center);
        }
      }
    }

    &.campaign_ai_agent_modal {
      .modal_body {
        .ai_details {
          font-size: 0.875em;
          font-weight: 400;
          line-height: 150%;
          color: $color-grey-500;

          p {
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            unicode-bidi: isolate;
            font-family: inherit !important;
          }
        }

        .btn {
          margin: 42px 0 0 auto;
        }
      }
    }

    &.editor_modal {
      &.template_large {
        .modal-content {
          max-width: 1200px !important;
        }
      }
      .modal_body {
        .editor_container {
          @include flex-position(center, center);
          flex-direction: column;
          height: 100dvh;
        }
        .btn-box {
          @include flex-position(flex-end, center);
          gap: 1rem;
          margin-top: 2rem;
        }
      }
    }

    &.update_brand_assets_modal {
      .modal_body {
        .update_content {
          p {
            font-size: 14px;
            font-weight: 500;
            line-height: 22.4px;
            &.first-paragraph {
              margin-bottom: 2rem;
            }
          }
          .asset {
            @include flex-position(flex-start, center);
            gap: 0.5rem;
            margin-bottom: 1rem;
            .bullet {
              width: 12px;
              height: 12px;
              background: $color-primary-600;
              border-radius: 50%;
            }
          }
        }

        .btn {
          text-align: center;
        }
      }
    }

    &.select_campaign_sender_modal {
      .modal_body {
        .select_sender {
          padding: 3rem;

          p {
            margin-bottom: 2rem;
            font-size: 14px;
            font-weight: 500;
            line-height: 24.2px;
            text-align: center;
          }

          .button_box {
            @include flex-position(center, center);
            margin-top: 3rem;
            padding: 20px;
          }
        }
      }
    }

    &.check_campaign_message_modal {
      .modal_body {
        .check_campaign_message {
          padding: 3rem;

          p {
            margin-bottom: 2rem;
            font-size: 14px;
            font-weight: 500;
            line-height: 24.2px;
            text-align: center;
          }

          .button_box {
            @include flex-position(center, center);
            margin-top: 3rem;
            padding: 20px;
          }
        }
      }
    }

    &.klaviyo_auth_modal {
      .modal_body {
        .auth_body {
          @include flex-position(center, center);
          flex-direction: column;
          gap: 3rem;
          padding: 2rem 2rem 3rem;

          .logo_box {
            @include flex-position(center, center);

            gap: 0.7rem;
            margin-bottom: 1.5rem;
          }

          .content {
            @include flex-position(center, center);
            flex-direction: column;
            gap: 3rem;

            .loader_box {
              @include flex-position(center, center);
              flex-direction: column;
              margin-top: 1rem;
            }

            p {
              font-size: 20px;
              font-weight: 500;
              line-height: 27px;
              text-align: center;
              color: $color-grey-500;
            }

            .wait {
              font-size: 14px;
              text-align: center;
            }

            .success_text {
              margin-top: 1rem;
              color: $color-success;
              text-align: center;
            }

            .error_text {
              margin-top: 1rem;
              color: $color-error;
              text-align: center;
            }

            .btn_box {
              @include flex-position(center, center);
              gap: 1rem;
              flex-direction: column;

              button {
                width: 150px;
              }

              .try_again_btn {
                background: $color-success;
                color: $color-white;
                border: 1px solid $color-success;
              }
            }
          }
        }
      }
    }

    &.klaviyo_template_modal {
      &.template_large {
        .modal-content {
          max-width: 1200px !important;
        }
      }

      .header_box {
        .header_title {
          font-size: 18px;
          font-weight: 600;
          line-height: 28px;
          margin-bottom: 0.4rem;
        }

        .header_description {
          font-size: 14px;
          font-weight: 400;
          color: $color-grey-500;
        }
      }

      .modal_body {
        .create_klaviyo_campaign_box {
          @include flex-position(center, center);
          flex-direction: column;
          text-align: center;
          padding: 2rem;
          gap: 1rem;
          width: 100%;
        }

        .table_container {
          .action {
            .dropdown-item {
              text-align: left;
            }

            svg path {
              stroke: $color-grey-400;
            }
          }
        }

        .template_preview {
          @include flex-position(flex-start, flex-start);
          width: 100%;
          min-height: 700px;

          .instruction_box {
            width: 40%;

            .back {
              @include flex-position(flex-start, center);
              gap: 0.4rem;
              margin-bottom: 1.5rem;
              cursor: pointer;

              p {
                margin: 0;
              }
            }

            p {
              font-size: 14px;
              font-weight: 400;
              line-height: 25px;
              margin-bottom: 1.5rem;
            }

            button {
              background: $color-grey-900;
              color: $color-white;
              border: 1px solid $color-grey-900;
            }
          }

          .preview_box {
            width: 60%;
            height: 100%;
            margin-top: -28px;

            iframe {
              width: 100%;
              height: auto;
              min-height: 500px;
              border: none;
            }

            .button_box {
              @include flex-position(flex-end, flex-start);
              width: 100%;
              gap: 1rem;
              margin-top: 3rem;

              .cancel_btn {
                background: $color-white;
                color: $color-grey-900;
                border: 1px solid $color-grey-900;
              }

              .use_btn {
                background: $color-grey-900;
                color: $color-white;
                border: 1px solid $color-grey-900;
              }
            }
          }
        }
      }
    }

    &.campaign_recepient_modal {
      .modal_body {
        .campaign_recipients {
          font-size: 0.875em;
          font-weight: 400;
          line-height: 150%;
          color: $color-grey-500;
          margin-top: -1rem;

          .title {
            font-size: 18px;
          }

          p {
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            unicode-bidi: isolate;
            font-family: inherit !important;
          }
        }

        li {
          margin-left: 2rem;
        }

        .btn {
          margin: 42px 0 0 auto;
        }
      }
    }

    &.campaign_schedule_modal {
      .modal_body {
        .heading {
          font-size: 24px;
          font-weight: 500;
          margin-top: 1rem;
          text-align: center;
        }

        .sub_heading {
          font-size: 16px;
          font-weight: 400;
          margin-top: 0.5rem;
          text-align: center;
        }

        .datepicker {
          @include flex-position(center, center);
          margin-top: 2.5rem;
        }

        .btn_container {
          margin: 2.5rem 0;
          @include flex-position(center, center);
        }
      }
    }

    .enter_prompt_form {
      @include flex-position(flex-start, center);
      padding: 6px 6px 6px 12px;
      width: 100%;
      border: 1px solid $color-grey-200;
      border-radius: 12px;

      &:focus-within {
        border: 1px solid $color-primary-600;
      }

      .form_group_container {
        margin: 0;
        width: inherit;

        .flex_input {
          padding: 0;
          font-size: 0.95em;
          border: none;

          &:focus-within {
            border: none !important;
          }
        }
      }

      .btn {
        margin: 0 !important;
        padding: 8px 16px;
        padding-bottom: 9px;
        font-size: 0.92em;
        font-weight: 500;
        border-radius: 8px;

        &.loading {
          padding: 7px 16px !important;
          padding-bottom: 8px !important;

          .loader_img {
            width: 22px !important;
          }
        }
      }
    }

    .enter_prompt_form_with_margin {
      margin-top: 56px;
      margin-bottom: 32px;
    }

    .enter_prompt_form_ask_ai {
      margin-top: 16px;
      font-size: 0.95em;
      font-weight: 600;
      font-style: italic;
      color: $color-primary-600;
      width: fit-content;
      cursor: pointer;

      span {
        color: $color-primary-600;
      }

      &:hover {
        font-weight: 700;

        span {
          text-decoration: underline;
        }
      }
    }
  }
}
