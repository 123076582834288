.sign_in_page {
  .auth_container {
    @include flex-position(flex-start, center);
    width: 100%;
    background: $color-white;

    .left_side {
      position: relative;
      width: 55%;
      min-height: 800px;
      // height: 100vh;
      z-index: 1;

      color: $color-white;
      // background: $color-primary-600;
      background: #578bff;
      &::after {
        position: absolute;
        content: "";
        z-index: 2;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          #1b1b1b 99.11%
        );
      }

      video {
        position: relative;
        z-index: 3;
      }

      .top_header {
        position: relative;
        z-index: 3;
        color: $color-white;
        font-size: 30px;
        font-weight: 600;
        padding: 3.5rem 1rem 1.5rem;
        margin-top: -1rem;
        // line-height: 72px;
      }

      .bottom_text {
        position: relative;
        z-index: 3;
        font-weight: 500;
        font-size: 20px;
        color: $color-white;
        line-height: 38px;
        padding: 2rem 1rem 0;
      }

      .scroll {
        @include flex-position(center, center);
        flex-direction: column;
        gap: 0rem;
        position: absolute;
        z-index: 3;
        bottom: 1rem;
        right: 2rem;
        cursor: pointer;

        p {
          color: $color-white;
        }
      }

      @include mdScreen {
        display: none;
      }
    }

    .form_container {
      @include flex-position(center, center);
      flex-direction: column;
      padding: 40px;
      width: 45%;
      min-height: 100vh;
      height: 100vh;

      @include lgScreen {
        padding: 40px 20px;
      }

      @include mdScreen {
        padding: 70px 20px;
        width: 100%;
        height: 100%;
        min-height: 100%;
      }

      @include xsScreen {
        padding: 70px 12px;
      }

      .form {
        width: 100%;
        max-width: 404px;

        .title {
          margin-bottom: 8px;
          font-size: 1.5em;
          font-weight: 700;
          color: $color-grey-900;
        }

        .sub_title {
          margin-bottom: 30px;
          font-size: 0.95em;
          color: $color-grey-500;
          letter-spacing: 0px;
        }

        .form-group {
          input::placeholder {
            color: $color-grey-300 !important;
          }
        }

        .forgot {
          @include flex-position(space-between, center);

          .form_group_container {
            margin: 0;
          }

          label a {
            font-size: 1em;
            font-weight: 500 !important;
            color: $color-grey-900;
          }

          a {
            font-weight: 500 !important;
            font-size: 0.875em;
            color: $color-primary-600;
          }
        }

        .actions {
          margin-top: 32px;

          .btn {
            width: 100%;
          }

          .google_auth {
            margin-top: 32px;

            .rule_text {
              @include flex-position(flex-start, center);
              gap: 12px;
              width: 100%;

              .rule {
                height: 1px;
                background: $color-grey-200;
                width: inherit;
              }

              p {
                font-size: 0.8em;
                width: inherit;
                text-align: center;
                color: $color-grey-500;
              }
            }

            .btn_tertiary {
              margin-top: 32px;

              p {
                color: $color-grey-900;
                font-weight: 600;
              }
            }
          }

          .sign_up_text {
            margin-top: 32px;
            font-size: 0.875em;
            font-weight: 400;
            text-align: center;
            color: $color-grey-900;

            a,
            .link {
              font-weight: 700;
              color: $color-primary-600;
              cursor: pointer;
            }
          }
        }
      }
    }
    &.sign_up {
      .form {
        .title {
          margin-bottom: 28px !important;
        }

        @media (max-height: 700px) {
          .title {
            margin-bottom: 20px !important;
          }

          .form_group_container {
            margin-bottom: 14px !important;
          }

          .actions {
            margin-top: 24px !important;

            .google_auth {
              margin-top: 24px !important;

              .btn_tertiary {
                margin-top: 24px !important;
              }
            }

            .sign_up_text {
              margin-top: 24px !important;
            }
          }
        }
      }
    }
  }

  .page_content {
    background: $color-white;
    padding: 7rem 0px 4rem;
    .features {
      @include flex-position(center, center);
      flex-direction: column;
      gap: 6rem;
      padding: 0rem 50px 7rem;

      .items {
        @include flex-position(center, flex-start);
        gap: 3rem;
        width: 100%;

        .content {
          @include flex-position(center, center);
          flex-direction: column;

          &.mt {
            margin-top: 6rem;
          }

          p {
            color: $color-grey-500;
            font-size: 16px;
            font-weight: 400;
            text-align: center;
          }
          .heading {
            margin: 0.8rem 0;
            color: $color-grey-900;
            font-size: 20px;
            font-weight: 600;
          }
        }
      }

      .cta {
        @include flex-position(center, center);
        flex-direction: column;
        text-align: center;
        gap: 2rem;
        margin-top: 1rem;
        .heading {
          font-size: 36px;
          font-weight: 600;
          color: $color-grey-900;
        }
        p {
          color: $color-grey-500;
          margin-top: -1.2rem;
        }
      }
    }
    .testimonial {
      @include flex-position(center, center);
      flex-direction: column;
      gap: 2rem;
      text-align: center;
      background: #f9fafb;
      padding: 7rem 50px;
      .heading {
        font-size: 36px;
        font-weight: 600;
        color: $color-grey-900;
      }
      .rating {
        @include flex-position(center, center);
      }
      p {
        color: $color-grey-500;
        font-size: 20px;
      }
      .comment {
        font-size: 24px;
        color: $color-grey-900;
        strong {
          font-style: italic;
        }
      }
      .logo {
        @include flex-position(center, center);
      }
    }
    .footer {
      padding: 4rem 50px 0;
      .top {
        @include flex-position(center, center);
        gap: 4rem;
        width: 100%;
        .item {
          @include flex-position(center, center);
          flex-direction: column;
          gap: 2rem;

          p {
            font-weight: 500px;
            font-size: 25px;
          }
        }
      }
      .bottom {
        @include flex-position(space-between, center);
        padding-top: 4rem;
        p {
          text-align: center;
          color: $color-primary-600;
        }
      }
    }
  }
}
